export default {
    name: 'cascadeBox',
    data () {
        return {
            showPop: false,
            inputActiveList: this.value
        }
    },
    props: {
        listDate: {
            type: Array,
            default: () => []
        },
        value: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        }
    },
    components: {
        radioTree: () => import('./radio-tree/index.vue')
    },
    created () {},
    mounted () {
        document.addEventListener('click', this.clickCascadeWrapper)
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
        document.removeEventListener('click', this.clickCascadeWrapper)
    },
    watch: {
        value(newVal) {
            this.inputActiveList = newVal
        },
        inputActiveList(newVal) {
            this.$emit('input', newVal)
        }
    },
    computed: {},
    filters: {},
    methods: {
        clickCascadeWrapper(e) {
            if (!!this.$refs.cascadeWrapper.contains(e.target)) return
            this.showPop = false
        },
        toggleShowPop() {
            this.showPop = !this.showPop
        },
        handlerExpand(val) {
            val.isExpand = !val.isExpand
        },
        toSelectRadio(val) {
            this.inputActiveList = [val]
            let childrenAllChange = function (list, item) {
                list.forEach(value => {
                    if (item.budgetOrgId === value.budgetOrgId) {
                        value.check = true
                    } else {
                        value.check = false
                    }
                    if (value.children && value.children.length > 0) {
                        childrenAllChange(value.children, item)
                    }
                })
            }
            childrenAllChange(this.listDate, val)
            this.$nextTick(() => {
                this.$emit('toSelect', val)
            })
        },
        delItem(val) {
            let _this = this
            this.delChoose([val])
            let eachToCancelCheck = function (list, val) {
                list.forEach(value => {
                    if (value.deptId === val.deptId) {
                        value.check = false
                    }
                    if (value.children && value.children.length > 0) {
                        eachToCancelCheck(value.children, val)
                    }
                })
            }
            eachToCancelCheck(this.listDate, val)
            this.$emit('delRadio', val)
        },
        delChoose(delList) {
            delList.forEach(value => {
                for (let i = 0, l = this.inputActiveList.length; i < l; i++) {
                    if (this.inputActiveList[i] === value) {
                        this.inputActiveList.splice(i, 1)
                        break
                    }
                }
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/